import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { useAppDispatch } from 'store';
import { Switch, StatusType } from 'uikit';
import { KeyboardIcon } from 'uikit/Icons';
import { getLettersThunk } from 'store/letter/thunk';
import AlphaBetView from './AlphaBetView/AlphaBetView';
import KeyboardView from './KeyboardView/KeyboardView';
import styles from "./letters.scss";

const LettersBox: React.FC = () => {

    const dispatch = useAppDispatch();
    const [statusSwitch, setStatusSwitch] = useState<StatusType>('off');

    useEffect(() => {
        dispatch(getLettersThunk());
    }, []);

    const handleClick = (newStatus: StatusType) => {
        setStatusSwitch(newStatus);
    }

    return <div className={styles.wrap}>
        <div className={styles.switchView}>
            <Switch
                status={statusSwitch}
                onClick={handleClick}
            />
            <div className={clsx(styles.keyboardIcon, statusSwitch !== 'on' && styles.keyboardIconOff)}>
                <KeyboardIcon />
            </div>
        </div>
        {
            statusSwitch !== 'on' &&
            <AlphaBetView />
        }
        {
            statusSwitch === 'on' &&
            <KeyboardView />
        }
    </div>;
}

export default LettersBox;
