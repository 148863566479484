import { AxiosInstance } from 'axios';
import api from '../api';
import { RiddleData } from 'entities';
import  { MessagesType, GetMessagesParamsType } from '../translates/entities';
import { CheckLetterParamsType, ResponseCheckLetter } from './entities';

function APIServiceRiddles() {

    let axios: AxiosInstance | null = null;

    function init(messages?: MessagesType) {
        axios = api.init(messages);
    }

    function getRiddleDataUrl() {
        return `/riddle/data`;
    }

    function getCheckLetterUrl(id: number, letter: string) {
        return `/riddles/${id}/checkletter/${letter}`;
    }

    const getRiddleData = async (params: GetMessagesParamsType): Promise<RiddleData> => {        
        if (axios) {
            const response = await axios.get<RiddleData>(
                getRiddleDataUrl(),
                { params },
            );

            return response.data;
        }
        throw Error('getRiddleData: axios is broken');
    }

    const checkLetter = async (params: CheckLetterParamsType): Promise<ResponseCheckLetter> => {        
        if (axios) {
            const { id, letter, ...otherParams } = params;
            const response = await axios.get<ResponseCheckLetter>(
                getCheckLetterUrl(id, letter),
                {
                    params: otherParams,
                },
            );

            return response.data;
        }
        throw Error('getRiddleData: axios is broken');
    }

    return {
        init,
        getRiddleData,
        checkLetter,
    }
}

export default APIServiceRiddles();
