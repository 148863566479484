import { createAsyncThunk } from '@reduxjs/toolkit';
import APIServiceRiddles from 'services/api/riddles';
import { ResponseCheckLetter } from 'services/api/riddles/entities';
import { RiddleData } from 'entities';
import { RootState } from '../index';
import { NO_ERROR_MESSAGE } from '../handler';

export const getRiddleDataThunk = createAsyncThunk<RiddleData, undefined, { rejectValue: string; state: RootState }>(
    'secretWord/getRiddleDataThunk',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const riddleData: RiddleData = await APIServiceRiddles.getRiddleData({ lang: state.translates.lang });
            return riddleData;
        } catch (error) {
            if (error instanceof Error) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue(NO_ERROR_MESSAGE);
        }
    }
);

export const checkLetterThunk = createAsyncThunk<ResponseCheckLetter, string, { rejectValue: string; state: RootState }>(
    'secretWord/checkLetterThunk',
    async (letter, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            if (state.secretWord.id === null) {
                throw Error('Riddle is absent');
            }
            const params = {
                lang: state.translates.lang,
                id: state.secretWord.id,
                letter,
            };
            const responseCheckLetter: ResponseCheckLetter = await APIServiceRiddles.checkLetter(params);
            return responseCheckLetter;
        } catch (error) {
            if (error instanceof Error) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue(NO_ERROR_MESSAGE);
        }
    }
);
