import { RootState } from 'store';
import { SecretWord } from './entities';

export const getSecretWord =
    (state: RootState): SecretWord =>
        state.secretWord.currentSecretWord;

export const getCurrentRiddle =
    (state: RootState): string =>
        state.secretWord.currentRiddle;

export const isOpenWord =
    (state: RootState): boolean =>
        state.secretWord.isOpenWord;

export const getCountOfError =
    (state: RootState): number =>
        state.secretWord.countOfError;

export const isStart =
    (state: RootState): boolean =>
        state.secretWord.isStart;

export const isResetProogressbar =
    (state: RootState): boolean =>
        state.secretWord.isResetProogressbar;
