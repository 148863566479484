import React, { useRef, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { startProgressBar } from 'store/secretword/slice';
import { checkLetterThunk } from 'store/secretword/thunk';
import { isResetProogressbar, getCountOfError, isOpenWord } from 'store/secretword/selectors';
import { getTranslates } from 'store/translates/selectors';
import styles from './progressbar.scss';

interface Props {
}

const ProgressBar: React.FC<Props> = (props) => {

    const WIDTH_TIME = 30;
    const ONE_SECOND = 1000;
    const MILISECOND100 = 100;
    const PERCENT100 = 100;
    const MIN_TIME = 1;
    const step = PERCENT100/(WIDTH_TIME*ONE_SECOND/MILISECOND100);
    const [ progress, setProgress ] = useState<number>(0);
    const [ timeLeft, setTimeLeft ] = useState<number>(WIDTH_TIME);
    const [ wrapWidth, setWrapWidth] = useState<number>(0);

    const dispatch = useAppDispatch();
    const isRestart = useAppSelector(isResetProogressbar);
    const translates = useAppSelector(getTranslates);

    const countOfError = useAppSelector(getCountOfError);
    const isOpen = useAppSelector(isOpenWord);
    const stop = countOfError > 9 || isOpen;

    const refIsStop = useRef<boolean>(false);
    const refIsRestart = useRef<boolean>(false);
    const wrapRef = useRef<HTMLDivElement>(null);

    refIsStop.current = stop;
    refIsRestart.current = isRestart;

    useEffect(() => {
        const width = wrapRef.current?.getBoundingClientRect().width;
        width && setWrapWidth(width);
    }, [wrapRef]);

    useEffect(() => {
        
        let timerProgressId: ReturnType<typeof setInterval>, timerId: ReturnType<typeof setInterval>;

        setProgress(0);
        setTimeLeft(WIDTH_TIME);
        const animateBar = () => {
            setProgress((currentValue) => {
                if (refIsRestart.current) {
                    dispatch(startProgressBar());
                    return 0;
                }
                return currentValue >= PERCENT100? 0: currentValue + step;
            });
            if (refIsStop.current) {
                resetIntervals();
            }            
        };

        const resetIntervals = () => {
            clearInterval(timerProgressId);
            clearInterval(timerId);
        }

        timerProgressId = setInterval(animateBar, MILISECOND100);

        timerId = setInterval(() => {
            setTimeLeft((currentValue) => {
                if (currentValue <= MIN_TIME) {
                    return WIDTH_TIME;
                }
                if (refIsRestart.current) {
                    return WIDTH_TIME;
                }
                return currentValue - 1
            });
        }, ONE_SECOND);

        const handleResizeWindow = async () => {
            const width = wrapRef.current?.getBoundingClientRect().width;
            width && setWrapWidth(width);
        };

        window.addEventListener('resize', handleResizeWindow);

        return () => {
            resetIntervals();
            window.removeEventListener('resize', handleResizeWindow);
        }
    },[refIsRestart.current]);

    useEffect(() => {
        if(timeLeft === MIN_TIME) {
            dispatch(checkLetterThunk(' '));
        }
    }, [timeLeft]);

    const currentWidth = progress * wrapWidth / 100;

    return <div className={styles.wrap} ref={wrapRef}>
        <div className={styles.progress} style={{width: currentWidth + 'px'}}></div>
        <div className={styles.message}>{translates.FOR_CHOICE_YOU_HAVE} {timeLeft} {translates.SEC}</div>
    </div>;
}

export default ProgressBar;
