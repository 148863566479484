import * as React from "react";
import styles from "./secretletter.scss";

interface Props {
    isOpen: boolean;
    symbol: string;
    id: number;
}

const SecretLetter: React.FC<Props> = (props) => {
    return <div className={styles.wrap}>
        {props.isOpen && props.symbol}
    </div>;
}

export default SecretLetter;
