import React from 'react';
import clsx from 'clsx';

import { CrossIcon } from '../Icons';
import styles from './Modal.scss';

type ModalProps = {
    onClose?: () => void;
    classes?: {
        modal?: string;
        cross?: string;
    }
};

export const Modal:React.FC<ModalProps> = (props) => {
    const { children, classes, onClose } = props;

    return (
        <div className={clsx(styles.modal, classes?.modal )}>
            {
                onClose && (
                    <span className={clsx(styles.cross, classes?.cross)} onClick={onClose}>
                        <CrossIcon />
                    </span>
                )
            }
            { children }
        </div>
    )
}
