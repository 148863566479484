// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".startGameMobileButton__wrapper___mR7ab {\n  padding: 20px 26px 24px 26px;\n  border: 1px solid #aaa;\n  background-color: #ccc;\n  border-radius: 3px;\n  font-family: \"Segoe UI\", Tahoma, Geneva, Verdana, sans-serif;\n  font-size: 30px;\n  font-weight: bold;\n  text-transform: uppercase;\n  margin-top: 40px;\n  color: rgba(0, 0, 0, 0.6);\n  opacity: 1;\n}\n.startGameMobileButton__wrapper___mR7ab:hover {\n  cursor: pointer;\n  background-color: #ddd;\n}\n.startGameMobileButton__wrapper___mR7ab.startGameMobileButton__active___9Ki5Y {\n  background-color: #ddd;\n}", "",{"version":3,"sources":["webpack://./src/components/GallowGame/StartGameMobileButton/startGameMobileButton.scss"],"names":[],"mappings":"AACA;EACE,4BAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;EACA,4DAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,gBAAA;EACA,yBAAA;EACA,UAAA;AAAF;AAEE;EACE,eAAA;EACA,sBAAA;AAAJ;AAGE;EACE,sBAAA;AADJ","sourcesContent":["@import \"src/components/GallowGame/GamePage/Gallow/_paint.scss\";\n.wrapper {\n  padding: 20px 26px 24px 26px;\n  border: 1px solid #aaa;\n  background-color: #ccc;\n  border-radius: 3px;\n  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n  font-size: 30px;\n  font-weight: bold;\n  text-transform: uppercase;\n  margin-top: 40px;\n  color: rgba(0,0,0,0.6);\n  opacity: 1;\n\n  &:hover {\n    cursor: pointer;\n    background-color: #ddd;\n  }\n\n  &.active {\n    background-color: #ddd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "startGameMobileButton__wrapper___mR7ab",
	"active": "startGameMobileButton__active___9Ki5Y"
};
export default ___CSS_LOADER_EXPORT___;
