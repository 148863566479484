import * as React from "react";
import { useAppSelector } from 'store';
import { getCountOfError } from 'store/secretword/selectors';
import styles from "./gallow.scss";

const Gallow: React.FC = () => {
    
    const countOfErrors: number = useAppSelector(getCountOfError);

    return <div className={styles.wrap}>
        {
            countOfErrors < 1 && <div className={styles.greyHeadElement}></div>
        }
        {
            countOfErrors < 2 && <div className={styles.greyBodyElement}></div>
        }
        {
            countOfErrors < 3 && <div className={styles.greyLeftLegElement}></div>
        }
        {
            countOfErrors < 4 && <div className={styles.greyRightLegElement}></div>
        }
        {
            countOfErrors < 5 && <div className={styles.greyLeftHandElement}></div>
        }
        {
            countOfErrors < 6 && <div className={styles.greyRightHandElement}></div>
        }
        {
            countOfErrors < 7 && <div className={styles.greyGallowsFoundElement}></div>
        }
        {
            countOfErrors < 8 && <div className={styles.greyGallowsElement}></div>
        }
        {
            countOfErrors < 9 && <div className={styles.greyGallowsArmElement}></div>
        }
        {
            countOfErrors < 10 && <div className={styles.greyGallowsRopeElement}></div>
        }
        {
            countOfErrors > 0 && <div className={styles.headElement}></div>
        }
        {
            countOfErrors > 1 && <div className={styles.bodyElement}></div>
        }
        {
            countOfErrors > 2 && <div className={styles.leftLegElement}></div>
        }
        {
            countOfErrors > 3 && <div className={styles.rightLegElement}></div>
        }
        {
            countOfErrors > 4 && <div className={styles.leftHandElement}></div>
        }
        {
            countOfErrors > 5 && <div className={styles.rightHandElement}></div>
        }
        {
            countOfErrors > 6 && <div className={styles.gallowsFoundElement}></div>
        }
        {
            countOfErrors > 7 && <div className={styles.gallowsElement}></div>
        }
        {
            countOfErrors > 8 && <div className={styles.gallowsArmElement}></div>
        }                            
        {
            countOfErrors > 9 && <div className={styles.gallowsRopeElement}></div>
        }     
    </div>;
}

export default Gallow;
