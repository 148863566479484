import React from 'react';
import clsx from 'clsx';
import styles from './Switch.scss';

export type StatusType = 'on' | 'off';

export interface SwitchProps {
    status?: StatusType;
    onClick: (newStatus: StatusType) => void;
}

export const Switch: React.FC<SwitchProps> = props => {
    const { status, onClick } = props;

    const handleClick = () => {
        const newStatus = status === 'on'
            ? 'off'
            : 'on';
        onClick(newStatus);
    }

    return <div className={clsx(styles.switchBtn, status === 'on' && styles.switchOn)} onClick={handleClick}>
    </div>;
};
