import React from 'react';
import { useAppSelector } from 'store';
import { LangType } from 'entities';
import { isMobileUserAgent } from 'services/app';
import { getLang } from 'store/translates/selectors';
import StartGameMobileButton from '../StartGameMobileButton';
import styles from './startpage.scss';
import startru from '../../../img/startru.png';
import starten from '../../../img/starten.png';
import startuk from '../../../img/startuk.png';

export interface StartPageProps {
}

const StartPage: React.FC<StartPageProps> = props => {
    const currentLang = useAppSelector(getLang);

    const isLangRu = currentLang === LangType.RU;
    const isLangEn = currentLang === LangType.EN;
    const isLangUk = currentLang === LangType.UK;
    const isMobile = isMobileUserAgent();

    return <div className={styles.gamearea}>
        {
            isLangRu &&
            <img src={startru} />
        }
        {
            isLangEn &&
            <img src={starten} />
        }
        {
            isLangUk &&
            <img src={startuk} />
        }
        {
            isMobile &&
            <StartGameMobileButton />
        }
    </div>
};

export default StartPage;
