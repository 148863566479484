import React from 'react';
import { Button, Select } from 'uikit';
import { LangType } from 'entities';
import { MenuProps } from '../index';
import styles from './DesktopMenu.scss';

const DesktopMenu: React.FC<MenuProps> = props => {
    const {
        messageStartGame,
        currentLang,
        languages,
        donateMessage,
        handleStartGame,
        handleChangeLanguage,
        handleDonate,
    } = props;

    return <div className={styles.wrap}>
        <Button onClick={handleStartGame}>
            {messageStartGame}
        </Button>
        <Select<LangType>
            onChange={handleChangeLanguage}
            options={languages}
            value={currentLang}
        />
        <Button onClick={handleDonate}>
            {donateMessage}
        </Button>
    </div>;
};

export default DesktopMenu;
