import React, { useEffect, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { isMobileUserAgent } from 'services/app';
import { isOpenWord, getCountOfError, isStart } from 'store/secretword/selectors';
import { showModal } from 'store/modal/slice';
import LettersBox from './LettersBox';
import WordBox from './WordBox';
import Gallow from './Gallow';
import ProgressBar from './ProgressBar';
import styles from './gamepage.scss';

export interface GamePageProps {
}

const GamePage: React.FC<GamePageProps> = props => {

    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(isOpenWord);
    const countOfError = useAppSelector(getCountOfError);
    const isStartPage = useAppSelector(isStart);

    useEffect(() => {
        if (isOpen) {
            dispatch(showModal({
                type: 'Win',
                props: null
            }));
        }
    },[isOpen]);

    useEffect(() => {
        if (countOfError > 9) {
            dispatch(showModal({
                type: 'Lose',
                props: null
            }));
        }
    },[countOfError]);

    const WordBoxMemo = useMemo(() => {
        return  <WordBox />;
    }, []);
    
    const LettersBoxMemo = useMemo(() => {
        return  <LettersBox />;
    }, []);

    const isMobile = isMobileUserAgent();
    return <div className={styles.gamearea}>
        <div className={styles.riddlearea}>
            <Gallow />
            {WordBoxMemo}
        </div>
        <div className={styles.isDesktopWrapper}>
            {
                !isMobile && !isStartPage &&
                <ProgressBar />
            }
        </div>
        {LettersBoxMemo}
    </div>;
};

export default GamePage;
