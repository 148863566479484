import { AlphaBetViewType, KeyboardViewType } from 'services/api/letters/entities';
import { RootState } from 'store';
import { Letters } from './entities';

export const getLetters =
    (state: RootState): Letters =>
        state.letters.letters;

export const getAlphaBetView = 
    (state: RootState): AlphaBetViewType =>
        state.letters.alphaBetView;

export const getKeyboardView = 
    (state: RootState): KeyboardViewType =>
        state.letters.keyboardView;
