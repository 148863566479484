import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RiddleData } from 'entities';
import { ResponseCheckLetter } from 'services/api/riddles/entities';
import { onReject } from '../handler';
import { SecretWordState } from './entities';
import { getRiddleDataThunk, checkLetterThunk } from './thunk';

const initialState: SecretWordState = {
    id: null,
    countLetters: 0,
    currentRiddle: '',
    currentSecretWord: [],
    isOpenWord: false,
    countOfError: 0,
    isStart: true,
    isResetProogressbar: false,
}

const secretWordSlice = createSlice({
    name: 'secretWord',
    initialState,
    reducers: {
        startProgressBar: (state, action: PayloadAction<undefined>) => {
            state.isResetProogressbar = false;
            return state;
        },        
    },
    extraReducers: (builder) => {
        builder.addCase(getRiddleDataThunk.fulfilled, (state, action: PayloadAction<RiddleData>) => {
            state.currentRiddle = action.payload.riddle;
            state.id = action.payload.id;
            state.countLetters = action.payload.countLetters;
            state.currentSecretWord = [];
            for(let i=0; i<action.payload.countLetters-1; i++) {
                state.currentSecretWord[i] = {
                    symbol: '',
                    isOpen: false
                }
            }            
            state.countOfError = 0;
            state.isOpenWord = false;
            state.isStart = false;
            state.isResetProogressbar = true;            
            return state;
        });
        builder.addCase(getRiddleDataThunk.rejected, (state, action) => {
            onReject(action.payload);
        });
        builder.addCase(checkLetterThunk.fulfilled, (state, action: PayloadAction<ResponseCheckLetter>) => {
            const { status, positions, letter } = action.payload;
            if (status) {
                positions.forEach((position) => {
                    state.currentSecretWord[position] = {
                        symbol: letter,
                        isOpen: true,
                    }
                })
            } else {
                state.countOfError = state.countOfError + 1;
            }
            state.isResetProogressbar = true;
            state.isOpenWord = true;
            Object.values(state.currentSecretWord).forEach((item) => {
                state.isOpenWord = state.isOpenWord && item.isOpen;
            })
            return state;
        });
        builder.addCase(checkLetterThunk.rejected, (state, action) => {
            onReject(action.payload);
        });
    },
});

export const {
    startProgressBar,
} = secretWordSlice.actions;

export default secretWordSlice.reducer;
