import React from 'react';
import { Modal } from 'uikit';
import { useAppSelector, useAppDispatch } from 'store';
import StartGameMobileButton from '../../StartGameMobileButton';
import { getTranslates } from 'store/translates/selectors';
import { hideModal } from 'store/modal/slice';
import styles from './WinModal.scss';

const WinImg: React.FC = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 299 299">
        <path fillRule="evenodd" d="M149.5,0A149.5,149.5,0,1,1,0,149.5,149.5,149.5,0,0,1,149.5,0Zm0,18A131.5,131.5,0,1,1,18,149.5,131.5,131.5,0,0,1,149.5,18Z"/>
        <ellipse cx="187" cy="103" rx="18" ry="28"/>
        <path fillRule="evenodd" d="M112,75c9.941,0,18,12.536,18,28s-8.059,28-18,28-18-12.536-18-28S102.059,75,112,75ZM58,169s38,57,90,55,67-23.5,95-54-27,38-94,36S58,169,58,169Z"/>
    </svg>;
}

export interface WinModalProps {
}

const WinModal: React.FC<WinModalProps> = () => {

    const dispatch = useAppDispatch();
    const translates = useAppSelector(getTranslates);

    const handleClose = () => {
        dispatch(hideModal());
    }

    return <Modal classes={{modal: styles.wrapper}}>
        <div className={styles.message}>
            {translates.YOU_WIN}
        </div>
        <div className={styles.imgStyle}>
            <WinImg />
        </div>
        <StartGameMobileButton onClick={handleClose}/>
    </Modal>;
};

export default WinModal;
