import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modals, ModalState } from './entities';

const initialState: ModalState = {
    type: 'None',
    props: null,
};

const modal = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        showModal: <T extends Modals>(state: T, action: PayloadAction<T>) => {
            state = action.payload;

            return state;
        },
        hideModal: (state, action: PayloadAction<void>) => {
            state = initialState;

            return state;
        },
    },
});

export const {
    showModal,
    hideModal,
} = modal.actions;

export default modal.reducer;
