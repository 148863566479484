export type RiddleData = {
    id: number;
    riddle: string;
    countLetters: number;
}

export enum LangType {
    RU = 'RU',
    EN = 'EN',
    UK = 'UK',
}
