import React from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';

export interface ButtonPropsInterface {
    onClick: () => void;
    children: React.ReactNode;
    isActive?: boolean;
}

export const Button: React.FC<ButtonPropsInterface> = props => {
    const {
        onClick,
        children,
        isActive,
    } = props;

    return (
        <div className={clsx(styles.button, isActive && styles.active)} onClick={onClick}>
            {children}
      </div>
    );
};
