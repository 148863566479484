import * as React from "react";
import { useAppDispatch } from 'store';
import { activeLetter } from 'store/letter/slice';
import { checkLetterThunk } from 'store/secretword/thunk';
import styles from "./letter.scss";

interface Props {
    isActive: boolean;
    symbol: string;
}

const LetterButton: React.FC<Props> = (props) => {
    const { isActive, symbol } = props;
    const dispatch = useAppDispatch();

    const onClickHandle = () => {
        if (isActive) {
            dispatch(activeLetter(symbol));
            dispatch(checkLetterThunk(symbol));
        }
    }

    return <>
        {
            isActive && <div onClick={onClickHandle} className={styles.letter}>{symbol}</div>
        }
        {
            !isActive && <div className={styles.letteroff}>{symbol}</div>
        }    
    </>
}

export default LetterButton;
