// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WinModal__wrapper___8\\+7dR {\n  opacity: 0.9;\n  background-color: white;\n  border: 1px solid #ddd;\n}\n\n.WinModal__message___uD3tW {\n  color: #333;\n  font-size: 40px;\n  display: block;\n  margin-bottom: 40px;\n}\n\n.WinModal__imgStyle___EUR3P {\n  width: 300px;\n}\n\n@media (min-width: 390px) and (max-width: 480px) {\n  .WinModal__imgStyle___EUR3P {\n    width: 200px;\n  }\n  .WinModal__wrapper___8\\+7dR {\n    opacity: 1;\n  }\n}\n@media (max-width: 390px) {\n  .WinModal__imgStyle___EUR3P {\n    width: 150px;\n  }\n  .WinModal__wrapper___8\\+7dR {\n    opacity: 1;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/GallowGame/GamePage/WinModal/WinModal.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,uBAAA;EACA,sBAAA;AAAJ;;AAGA;EACI,WAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACI;IACI,YAAA;EAAN;EAGE;IACI,UAAA;EADN;AACF;AAIA;EACI;IACI,YAAA;EAFN;EAKE;IACI,UAAA;EAHN;AACF","sourcesContent":["@import \"src/components/GallowGame/GamePage/Gallow/_paint.scss\";\n.wrapper {\n    opacity: .9;\n    background-color: white;\n    border: 1px solid #ddd;    \n}\n  \n.message {\n    color: #333;\n    font-size: 40px;\n    display: block;\n    margin-bottom: 40px;    \n}\n  \n.imgStyle {\n    width: 300px;\n}\n  \n@media (min-width: 390px) and (max-width: 480px) {\n    .imgStyle {\n        width: 200px;\n    }\n\n    .wrapper {\n        opacity: 1;\n    }\n}\n  \n@media (max-width: 390px) {\n    .imgStyle {\n        width: 150px;\n    }\n  \n    .wrapper {\n        opacity: 1;\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "WinModal__wrapper___8+7dR",
	"message": "WinModal__message___uD3tW",
	"imgStyle": "WinModal__imgStyle___EUR3P"
};
export default ___CSS_LOADER_EXPORT___;
