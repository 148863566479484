import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlphaBetViewType, KeyboardViewType } from 'services/api/letters/entities';
import { onReject } from '../handler';
import { LettersState } from './entities';
import { getLettersThunk } from './thunk';

const initialState: LettersState = {
  letters: {},
  alphaBetView: [],
  keyboardView: [],
}

const letterSlice = createSlice({
    name: 'letter',
    initialState,
    reducers: {
        setLetters: (state, action: PayloadAction<AlphaBetViewType>) => {
            state.alphaBetView = action.payload;
            action.payload.forEach((symbol) => {
                state.letters[symbol] = true;
            });
            return state;
        },
        setKeyboardView: (state, action: PayloadAction<KeyboardViewType>) => {
            state.keyboardView = action.payload;
            return state;
        },        
        refreshLetters: (state, action: PayloadAction<undefined>) => {
            Object.keys(state.letters).forEach((key) => {
                state.letters[key] = true;
            });
            return state;
        },
        activeLetter: (state, action: PayloadAction<string>) => {
            state.letters[action.payload] = false;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLettersThunk.fulfilled, (state, action) => {
            return state;
        });
        builder.addCase(getLettersThunk.rejected, (state, action) => {
            onReject(action.payload);
        });
    },
});

export const {
    setLetters,
    setKeyboardView,
    refreshLetters,
    activeLetter,
} = letterSlice.actions;

export default letterSlice.reducer;
