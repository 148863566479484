import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LangType } from 'entities';
import { onReject } from '../handler';
import { TranslatesState } from './entities';
import { MessagesType } from 'services/api/translates/entities';
import { getTranslatesThunk } from './thunk';

const initialState: TranslatesState = {
    messages: {},
    lang: LangType.RU
}

const translatesSlice = createSlice({
    name: 'translates',
    initialState,
    reducers: {
        setTranslates: (state, action: PayloadAction<MessagesType>) => {
            state.messages = action.payload;
            return state;
        },
        setLanguage: (state, action: PayloadAction<LangType>) => {
            state.lang = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTranslatesThunk.fulfilled, (state, action) => {
            return state;
        });
        builder.addCase(getTranslatesThunk.rejected, (state, action) => {
            onReject(action.payload);
        });
    },
});

export const {
    setTranslates,
    setLanguage,
} = translatesSlice.actions;

export default translatesSlice.reducer;
