import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useAppSelector, useAppDispatch } from 'store';
import { Modals, NoneModal } from 'store/modal/entities';
import { getModal } from 'store/modal/selectors';
import { hideModal } from 'store/modal/slice';
import WinModal from '../GamePage/WinModal/WinModal';
import LoseModal from '../GamePage/LoseModal/LoseModal';
import styles from './ModalContainer.scss';

function getDomContainer(name: string): HTMLElement {
    const containerId = name;

    let container: HTMLElement | null = document.getElementById(containerId);

    if (!container) {
        container = document.createElement('div');
        container.id = containerId;

        document.body.appendChild(container)
    }

    return container;
}

const modalContainer = getDomContainer('modal');

const modals: Record<Exclude<Modals, NoneModal>['type'], any> = {
    'Win': WinModal,
    'Lose': LoseModal,
}

export interface ModalContainerProps {
}

const ModalContainer: React.FC<ModalContainerProps> = props => {

    const modal = useAppSelector(getModal())
    const dispatch = useAppDispatch();
    const modalWrapperRef = useRef<HTMLDivElement>(null);

    const modalClickHandler: React.MouseEventHandler<HTMLDivElement> = (event) => {
        /** TODO close modal when click out of modal, return when needed */
        /* 
        if (modalWrapperRef.current) {
            if (!event.nativeEvent.composedPath().includes(modalWrapperRef.current)) {
                dispatch(hideModal());
            }
        }*/
    }

    if (modal.type === 'None') {
        return null
    }

    const Modal = modals[modal.type];

    return (
        ReactDOM.createPortal(
            <div
                onClick={modalClickHandler}
                className={styles.modalsContainerBackground}
            >
                <div ref={modalWrapperRef}>
                    <Modal />
                </div>
            </div>
            ,
            modalContainer,
        )
    );
};

export default ModalContainer;
