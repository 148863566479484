// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DesktopMenu__wrap___VEVCO {\n  padding: 5px;\n  border: 1px solid #eee;\n  background-color: white;\n  border-radius: 3px;\n  display: flex;\n}\n\n@media (min-width: 768px) and (max-width: 1024px) {\n  .DesktopMenu__wrap___VEVCO {\n    padding: 2px;\n  }\n}\n@media (max-width: 480px) {\n  .DesktopMenu__wrap___VEVCO {\n    display: none;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/GallowGame/Header/DesktopMenu/DesktopMenu.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;AAAF;;AAGA;EACE;IACE,YAAA;EAAF;AACF;AAGA;EACE;IACE,aAAA;EADF;AACF","sourcesContent":["@import \"src/components/GallowGame/GamePage/Gallow/_paint.scss\";\n.wrap {\n  padding: 5px;\n  border: 1px solid #eee;\n  background-color: white;\n  border-radius: 3px;\n  display: flex;\n}\n\n@media (min-width: 768px) and (max-width: 1024px) {\n  .wrap {\n    padding: 2px;\n  }\n}\n\n@media (max-width: 480px) {\n  .wrap {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "DesktopMenu__wrap___VEVCO"
};
export default ___CSS_LOADER_EXPORT___;
