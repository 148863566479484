import * as React from 'react';
import styles from "./gallowgame.scss";
import { useAppDispatch, useAppSelector } from 'store';
import { getTranslatesThunk } from 'store/translates/thunk';
import { isStart } from 'store/secretword/selectors';
import Header from './Header';
import StartPage from './StartPage';
import GamePage from './GamePage';
import ModalContainer from './ModalContainer/ModalContainer';

const GallowGame: React.FC = () => {
    
    const dispatch = useAppDispatch();
    const isShowStartPage = useAppSelector(isStart);

    React.useEffect(() => {
        dispatch(getTranslatesThunk());
    }, []);

    return <div className={styles.wrap}>
        <Header />
        {
            isShowStartPage &&
            <StartPage />
        }
        {
            !isShowStartPage &&
            <GamePage />
        }
        <ModalContainer />
    </div>;
}

export default GallowGame;
