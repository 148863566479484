import React from 'react';
import { Modal } from 'uikit';
import { useAppSelector, useAppDispatch } from 'store';
import StartGameMobileButton from '../../StartGameMobileButton';
import { getTranslates } from 'store/translates/selectors';
import { hideModal } from 'store/modal/slice';
import styles from './LoseModal.scss';

const GameOverImg: React.FC = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 299 291">
        <path fill="#fff" fillRule="evenodd" d="M149.5,0C232.067,0,299,65.143,299,145.5S232.067,291,149.5,291,0,225.857,0,145.5,66.933,0,149.5,0ZM149,18c73.454,0,133,57.755,133,129S222.454,276,149,276,16,218.245,16,147,75.546,18,149,18Z"/>
        <path fill="#fff" fillRule="evenodd" d="M171.124,136.3l46.758-46.579,9.881,9.919L181,146.22Z"/>
        <path fill="#fff" fillRule="evenodd" d="M181.381,88.188l47.055,46.28-9.817,9.981-47.055-46.28Z"/>
        <rect fill="#fff" x="69" y="188" width="161" height="16"/>
        <path fill="#fff" fillRule="evenodd" d="M71.262,134.227l47.279-46.051L128.309,98.2,81.031,144.256Z"/>
        <path fill="#fff" fillRule="evenodd" d="M80.159,87.218l47.9,45.4-9.631,10.162-47.9-45.4Z"/>
        <path fill="#fff" fillRule="evenodd" d="M163,204h67v13.263A31.737,31.737,0,0,1,198.263,249,35.263,35.263,0,0,1,163,213.737V204Zm15,0h37v13.474A17.526,17.526,0,0,1,197.474,235,19.474,19.474,0,0,1,178,215.526V204Z"/>
    </svg>;
}

export interface LoseModalProps {
}

const LoseModal: React.FC<LoseModalProps> = () => {

    const dispatch = useAppDispatch();
    const translates = useAppSelector(getTranslates);

    const handleClose = () => {
        dispatch(hideModal());
    }

    return <Modal
        classes={{
            modal: styles.wrapper,
            cross: styles.cross,
        }}
    >
        <div className={styles.message}>
            {translates.YOU_LOSE}
        </div>
        <div className={styles.imgStyle}>
            <GameOverImg />
        </div>
        <StartGameMobileButton onClick={handleClose}/>
    </Modal>;
};

export default LoseModal;
