import { createAsyncThunk } from '@reduxjs/toolkit';
import APIServiceLetters from 'services/api/letters';
import { RootState } from '../index';
import { NO_ERROR_MESSAGE } from '../handler';
import { setLetters, setKeyboardView } from './slice';

export const getLettersThunk = createAsyncThunk<void, undefined, { rejectValue: string; state: RootState }>(
    'letter/getLettersThunk',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const letters = await APIServiceLetters.getLetters({ lang: state.translates.lang});
            thunkAPI.dispatch(setLetters(letters.letters));
            thunkAPI.dispatch(setKeyboardView(letters.keyboardView));
        } catch (error) {
            if (error instanceof Error) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue(NO_ERROR_MESSAGE);
        }
    }
);
