import { AxiosInstance } from 'axios';
import api from '../api';
import  { MessagesType, GetMessagesParamsType } from './entities';

function APIServiceTranslates() {

    let axios: AxiosInstance | null = null;

    function init(messages?: MessagesType) {
        axios = api.init(messages);
    }

    function getTranslatesUrl() {
        return `/translates`;
    }

    const getTranslates = async (params: GetMessagesParamsType): Promise<MessagesType> => {        
        if (axios) {
            const response = await axios.get<MessagesType>(
                getTranslatesUrl(),
                { params },
            );

            return response.data;
        }
        throw Error('axios is broken');
    }

    return {
        init,
        getTranslates,
    }
}

export default APIServiceTranslates();
