// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalContainer__modals-container-background___T1ezV {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.8);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 100;\n}\n\n.ModalContainer__modal-wrapper___S1\\+xt {\n  position: relative;\n  z-index: 1;\n}", "",{"version":3,"sources":["webpack://./src/components/GallowGame/ModalContainer/ModalContainer.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,UAAA;AAAJ","sourcesContent":["@import \"src/components/GallowGame/GamePage/Gallow/_paint.scss\";\n.modals-container-background {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.8);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 100;\n}\n\n.modal-wrapper {\n    position: relative;\n    z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modals-container-background": "ModalContainer__modals-container-background___T1ezV",
	"modalsContainerBackground": "ModalContainer__modals-container-background___T1ezV",
	"modal-wrapper": "ModalContainer__modal-wrapper___S1+xt",
	"modalWrapper": "ModalContainer__modal-wrapper___S1+xt"
};
export default ___CSS_LOADER_EXPORT___;
