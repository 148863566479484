// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal__modal___i89X\\+ {\n  width: 860px;\n  height: 746px;\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: center;\n  align-items: center;\n  border-radius: 3px;\n  position: relative;\n}\n\n.Modal__cross___8JjAg {\n  position: absolute;\n  right: 16px;\n  top: 16px;\n  cursor: pointer;\n  color: #a2abb3;\n}\n.Modal__cross___8JjAg:hover {\n  color: unset;\n}", "",{"version":3,"sources":["webpack://./src/uikit/Modal/Modal.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,aAAA;EACA,aAAA;EACA,wBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,cAAA;AAAJ;AAEI;EACI,YAAA;AAAR","sourcesContent":["@import \"src/components/GallowGame/GamePage/Gallow/_paint.scss\";\n.modal {\n    width: 860px;\n    height: 746px;\n    display: flex;\n    flex-flow: column nowrap;\n    justify-content: center;\n    align-items: center;\n    border-radius: 3px;\n    position: relative;\n}\n\n.cross {\n    position: absolute;\n    right: 16px;\n    top: 16px;\n    cursor: pointer;\n    color: #a2abb3;\n\n    &:hover {\n        color: unset;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "Modal__modal___i89X+",
	"cross": "Modal__cross___8JjAg"
};
export default ___CSS_LOADER_EXPORT___;
