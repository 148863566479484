import * as React from "react";
import { useAppSelector } from 'store';
import { getSecretWord, getCurrentRiddle } from 'store/secretword/selectors';
import { getTranslates } from 'store/translates/selectors';
import { SecretWord } from 'store/secretword/entities';
import { isMobileUserAgent } from 'services/app';
import SecretLetter from './SecretLetter';
import styles from './wordbox.scss';

const WordBox: React.FC = () => {

    const secretWord: SecretWord = useAppSelector(getSecretWord);
    const riddle: string = useAppSelector(getCurrentRiddle);
    const translates = useAppSelector(getTranslates);

    const isMobile = isMobileUserAgent();

    return <div className={styles.wrap}>
        {
            !isMobile &&
            <div className={styles.riddleHead} >{translates.GUESS_THE_WORD}</div>
        }
        <div className={styles.riddle} dangerouslySetInnerHTML={{__html: translates.PROMPT+riddle}} />
        <div className={styles.wrapWord}>    
            {
                Object.keys(secretWord).map(key => {

                const symbol = secretWord[Number(key)].symbol;
                
                return <SecretLetter
                        key={`${key}-${symbol}`}
                        id={Number(key)}
                        symbol={symbol}
                        isOpen={secretWord[Number(key)].isOpen}
                    />;
                })
            }
        </div>
    </div>;
}

export default WordBox;
