import React from "react";
import { useAppDispatch, useAppSelector } from 'store';
import { refreshLetters } from 'store/letter/slice';
import { isStart } from 'store/secretword/selectors';
import { getTranslates, getLang } from 'store/translates/selectors';
import { setLanguage } from 'store/translates/slice';
import { getRiddleDataThunk } from 'store/secretword/thunk';
import { getTranslatesThunk } from 'store/translates/thunk';
import { getLettersThunk } from 'store/letter/thunk';
import { LangType } from 'entities';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

export type LanguagesType = Array<{
    name: string;
    value: LangType;
}>;

export interface MenuProps {
    messageStartGame: string;
    currentLang: LangType;
    languages: LanguagesType;
    donateMessage: string;
    handleStartGame: () => void;
    handleChangeLanguage: (value: LangType) => void;
    handleDonate: () => void;    
}

const Header: React.FC = () => {
    
    const dispatch = useAppDispatch();
    const isStartPage = useAppSelector(isStart);
    const translates = useAppSelector(getTranslates);
    const currentLang = useAppSelector(getLang);

    React.useEffect(() => {
        const the_title = document.querySelector("title");
        if (the_title && translates?.TITLE) {
            the_title.innerHTML = translates.TITLE;
        }
    }, [currentLang]);

    const handleStartGame = async () => {
        await dispatch(getRiddleDataThunk());
        dispatch(refreshLetters());
    }

    const handleChangeLanguage = async (lang: LangType) => {
        if (isStartPage) {
            dispatch(setLanguage(lang));
            await dispatch(getRiddleDataThunk());
            await dispatch(getTranslatesThunk());
        } else {
            dispatch(setLanguage(lang));
            await dispatch(getTranslatesThunk());
            await dispatch(getLettersThunk());
            await dispatch(getRiddleDataThunk());
            dispatch(refreshLetters());
        }
    }

    const languages = [
        {name: translates.RU, value: LangType.RU},
        {name: translates.UK, value: LangType.UK},
        {name: translates.EN, value: LangType.EN},
    ];

    const handleDonate = () => {
        window.open('https://www.donationalerts.com/r/gallowdev', '_blank');
    }

    const messageStartGame = isStartPage? translates.START_GAME: translates.NEW_GAME;

    const props = {
        messageStartGame,
        currentLang,
        languages,
        donateMessage: translates.DONATE,
        handleStartGame,
        handleChangeLanguage,
        handleDonate,
    }

    return <>
        <DesktopMenu {...props} />
        <MobileMenu {...props} />
    </>;
}

export default Header;
