import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { useAppSelector } from 'store';
import { isOpenWord, getCountOfError, isStart } from 'store/secretword/selectors';
import { isMobileUserAgent } from 'services/app';
import { MenuProps } from '../index';
import { BurgerIcon, CheckMarkIcon } from 'uikit/Icons';
import ProgressBar from '../../GamePage/ProgressBar';
import styles from './MobileMenu.scss';

const TIME_TO_CLOSE_MENU = 400;

const MobileMenu: React.FC<MenuProps> = props => {
    const {
        messageStartGame,
        currentLang,
        languages,
        donateMessage,
        handleStartGame,
        handleChangeLanguage,
        handleDonate,
    } = props;

    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
    const [isVisibleMobileMenu, setIsVisibleMobileMenu] = useState(false);
    const [isOpenLangList, setIsOpenLangList] = useState(false);
    const [isVisibleLangList, setIsVisibleLangList] = useState(false);

    const isStartPage = useAppSelector(isStart);
    const isOpen = useAppSelector(isOpenWord);
    const countOfError = useAppSelector(getCountOfError);

    const menuRef = useRef<HTMLDivElement>(null);

    const smoothlyCloseMobileMenu = async () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                setIsVisibleMobileMenu(false);
                resolve(1);
              }, TIME_TO_CLOSE_MENU);
        });
    }

    const handleClickBurger = async () => {
        setIsOpenMobileMenu(!isOpenMobileMenu);
        if (isOpenMobileMenu) {
            await smoothlyCloseMobileMenu();
        } else {
          setIsVisibleMobileMenu(true);
        }
    }

    const smoothlyCloseLangList = async () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                setIsVisibleLangList(false);
                resolve(1);
              }, TIME_TO_CLOSE_MENU);
        });
    }

    const handleOpenLangList = async () => {
        setIsOpenLangList(!isOpenLangList);
        if (isOpenLangList) {
            await smoothlyCloseLangList();
        } else {
            setIsVisibleLangList(true);
        }        
    }

    const handleClickStartGame = () => {
        handleClickBurger();
        handleStartGame();
    }

    const handleClickDonate = () => {
        handleClickBurger();
        handleDonate();
    }

    useEffect(() => {
        const handleOutsideClick = async (event: any) => {
            if (menuRef && !menuRef.current?.contains(event.target)) {
                if (isOpenMobileMenu) {
                    await handleClickBurger();
                }
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpenMobileMenu]);

    const selectedOption = languages.find((option) => {
        return currentLang !== undefined ? option.value === currentLang : true;
    });

    const isMobile = isMobileUserAgent();
    const isShowProgressBar = isMobile && !isStartPage && !isOpen && countOfError <= 9;

    return <div className={styles.mobileMenuWrapper} ref={menuRef}>
        <div className={styles.burger} onClick={handleClickBurger}>
            <BurgerIcon />
        </div>
        {
            isShowProgressBar &&
            <ProgressBar />
        }
        {
            isVisibleMobileMenu &&
            <ul 
                className={clsx(
                    isOpenMobileMenu && styles.showMenuClass,
                    !isOpenMobileMenu && styles.hideMenuClass,
                )}
            >
                <li onClick={handleClickStartGame}>{messageStartGame}</li>
                <li>
                    <span onClick={handleOpenLangList}>
                        {selectedOption?.name}
                        <span className={clsx(styles.icon, isVisibleLangList && styles.iconOpened)}></span>
                    </span>
                    {
                        isVisibleLangList &&
                        <ul className={clsx(
                                styles.langList,
                                isOpenLangList && styles.showLangListClass,
                                !isOpenLangList && styles.hideLangListClass,
                            )}
                        >
                            {
                                languages.map((item) => {

                                    const isCurrentLanguage = item.value === currentLang;

                                    const handleClickChangeLanguage = async () => {
                                        await handleOpenLangList();
                                        await handleClickBurger();
                                        handleChangeLanguage(item.value);
                                    }

                                    return <li
                                        key={`${item.name}-${item.value}`}
                                        className={clsx(isCurrentLanguage && styles.currentLanguage)}
                                        onClick={handleClickChangeLanguage}
                                    >
                                        {
                                            isCurrentLanguage &&
                                            <span className={styles.checkMark}><CheckMarkIcon /></span>
                                        }
                                        {item.name}
                                    </li>
                                })
                            }
                        </ul>
                    }
                </li>
                <li onClick={handleClickDonate} className={styles.donate}>{donateMessage}</li>
            </ul>
        }
    </div>;
};

export default MobileMenu;
