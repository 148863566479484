import React from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { getTranslates } from 'store/translates/selectors';
import { getRiddleDataThunk } from 'store/secretword/thunk';
import { refreshLetters } from 'store/letter/slice';
import styles from './startGameMobileButton.scss';

export interface StartGameMobileButtonProps {
    onClick?: () => void;
}

const StartGameMobileButton: React.FC<StartGameMobileButtonProps> = (props) => {
    const { onClick } = props;

    const dispatch = useAppDispatch();
    const translates = useAppSelector(getTranslates);

    const handleStartGame = async () => {
        onClick && onClick();
        await dispatch(getRiddleDataThunk());
        dispatch(refreshLetters());
    }

    return <div className={styles.wrapper} onClick={handleStartGame}>
        {translates.START_GAME}
    </div>;
};

export default StartGameMobileButton;
