import { createAsyncThunk } from '@reduxjs/toolkit';
import APIServiceTranslates from 'services/api/translates';
import { RootState } from '../index';
import { NO_ERROR_MESSAGE } from '../handler';
import { MessagesType } from 'services/api/translates/entities';
import { setTranslates } from './slice';

export const getTranslatesThunk = createAsyncThunk<void, undefined, { rejectValue: string; state: RootState }>(
    'translates/getTranslatesThunk',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const messages: MessagesType = await APIServiceTranslates.getTranslates({ lang: state.translates.lang });
            thunkAPI.dispatch(setTranslates(messages));
        } catch (error) {
            if (error instanceof Error) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue(NO_ERROR_MESSAGE);
        }
    }
);
