import { AxiosInstance } from 'axios';
import api from '../api';
import  { ResponseLettersType } from './entities';
import  { MessagesType, GetMessagesParamsType } from '../translates/entities';

function APIServiceLetters() {

    let axios: AxiosInstance | null = null;

    function init(messages?: MessagesType) {
        axios = api.init(messages);
    }

    function getLettersUrl() {
        return `/letters`;
    }

    const getLetters = async (params: GetMessagesParamsType): Promise<ResponseLettersType> => {        
        if (axios) {
            const response = await axios.get<ResponseLettersType>(
                getLettersUrl(),
                { params },
            );

            return response.data;
        }
        throw Error('getLetters: axios is broken');
    }

    return {
        init,
        getLetters,
    }
}

export default APIServiceLetters();