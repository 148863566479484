import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { RiddleData  } from 'entities';
import { MessagesType } from './translates/entities';
import { ResponseLettersType } from './letters/entities';

type CommonType = RiddleData
    | MessagesType
    | ResponseLettersType ;

export type ResponseType<T> = {
    result: T;
    errors?: string[];
}

function APIService() {
    let prefixDefault = '';
    let apiVersionDefault = '/api/v1';
    
    const messagesDefault: MessagesType = {
        ERROR_UNEXPECTED: 'ERROR_UNEXPECTED',
        ERROR_OFFLINE: 'ERROR_OFFLINE',
        DEFAULT: 'DEFAULT',
    };

    let axiosInner: AxiosInstance | null = null;

    function init(
        messages = messagesDefault,
        prefix: string = prefixDefault,
        apiVersion: string = apiVersionDefault,
    ) {
        let baseURL = `${apiVersion}${prefix}`;

        axiosInner = axios.create({
            baseURL,
        });

        axiosInner.interceptors.response.use((response: AxiosResponse<ResponseType<CommonType>>) => {
            if (response.data.errors) {
                throw new Error(formatErrors(response.data.errors));
            }

            return response;
        }, (err) => {
            let errorMessage = messages.ERROR_UNEXPECTED;
            if (err.message) {
                const regExp = /(network( )?error)/gi;
                const isNetworkError = regExp.test(err.message);
                if (isNetworkError) {
                    errorMessage = messages.ERROR_OFFLINE;
                }
            }
            if (err.response?.status >= 500) {
                errorMessage = messages.DEFAULT;
            }
            if (err.response?.status === 400) {
                errorMessage = formatErrors(err.response.data.errors);
            }
            throw new Error(errorMessage);
        });

        return axiosInner;
    }

    function formatErrors(errors: string[]) {
        return errors.join('. ') + (errors.length > 1 ? '.' : '');
    }

    return {
        init,
        axios: axiosInner,
    }
}

export default APIService();
