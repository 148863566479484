// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Switch__switch-btn___ZLF8- {\n  display: inline-block;\n  width: 36px;\n  height: 18px;\n  border-radius: 9px;\n  background: #bfbfbf;\n  z-index: 0;\n  margin: 0;\n  padding: 0;\n  border: none;\n  cursor: pointer;\n  position: relative;\n  transition-duration: 300ms;\n}\n\n.Switch__switch-btn___ZLF8-::after {\n  content: \"\";\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n  background: #fff;\n  top: 3px;\n  left: 3px;\n  transition-duration: 300ms;\n  position: absolute;\n  z-index: 1;\n}\n\n.Switch__switch-on___8kpzy {\n  background: #4a90e2;\n}\n\n.Switch__switch-on___8kpzy::after {\n  left: 19px;\n}", "",{"version":3,"sources":["webpack://./src/uikit/Switch/Switch.scss"],"names":[],"mappings":"AACA;EACI,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,0BAAA;AAAJ;;AAGA;EACI,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,QAAA;EACA,SAAA;EACA,0BAAA;EACA,kBAAA;EACA,UAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ;;AAGA;EACI,UAAA;AAAJ","sourcesContent":["@import \"src/components/GallowGame/GamePage/Gallow/_paint.scss\";\n.switch-btn {\n    display: inline-block;\n    width: 36px;\n    height: 18px;\n    border-radius: 9px;\n    background: #bfbfbf;\n    z-index: 0;\n    margin: 0;\n    padding: 0;\n    border: none;\n    cursor: pointer;\n    position: relative;\n    transition-duration: 300ms;\n}\n\n.switch-btn::after {\n    content: \"\";\n    height: 12px;\n    width: 12px;\n    border-radius: 50%;\n    background: #fff;\n    top: 3px;\n    left: 3px;\n    transition-duration: 300ms;\n    position: absolute;\n    z-index: 1;\n}\n\n.switch-on {\n    background: #4a90e2;\n}\n\n.switch-on::after {\n    left: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch-btn": "Switch__switch-btn___ZLF8-",
	"switchBtn": "Switch__switch-btn___ZLF8-",
	"switch-on": "Switch__switch-on___8kpzy",
	"switchOn": "Switch__switch-on___8kpzy"
};
export default ___CSS_LOADER_EXPORT___;
