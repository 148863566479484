import React from 'react';

const CrossIcon: React.FC = () => {

    return <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path
            fill='currentColor'
            fillRule='evenodd'
            d='M14.667 12L24 21.333 21.333 24 12 14.667 2.667 24 0 21.333 9.333 12 0 2.667 2.667 0 12 9.333 21.333 0 24 2.667z'
        />
    </svg>;
};

export default CrossIcon;
