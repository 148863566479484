import * as React from "react";
import { Provider } from 'react-redux';
import store from 'store';
import GallowGame from './GallowGame';

const App: React.FC = () => 
    <Provider store = {store}>
        <GallowGame />
    </Provider>;

export default App;
