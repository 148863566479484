import React from 'react';

const KeyboardIcon: React.FC = () => {

    return <svg version="1.1" id="Artwork" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512">
        <g>
            <path d="M501,379V133c0-14.7-12.7-26.7-28.3-26.7H39.3c-15.6,0-28.3,12-28.3,26.7v246c0,14.7,12.7,26.7,28.3,26.7h433.4
                C488.3,405.7,501,393.8,501,379z M35.5,379V133c0-0.9,1.5-2.2,3.8-2.2h433.4c2.4,0,3.8,1.4,3.8,2.2v246c0,0.9-1.5,2.2-3.8,2.2H39.3
                C37,381.2,35.5,379.9,35.5,379z"/>
            <circle cx="99.3" cy="191.1" r="15.3"/>
            <circle cx="162" cy="191.1" r="15.3"/>
            <circle cx="224.6" cy="191.1" r="15.3"/>
            <circle cx="287.3" cy="191.1" r="15.3"/>
            <circle cx="350" cy="191.1" r="15.3"/>
            <circle cx="412.7" cy="191.1" r="15.3"/>
            <circle cx="130.7" cy="256" r="15.3"/>
            <circle cx="193.3" cy="256" r="15.3"/>
            <circle cx="256" cy="256" r="15.3"/>
            <circle cx="318.6" cy="256" r="15.3"/>
            <path d="M381.3,240.7L381.3,240.7c-8.5,0-15.3,6.9-15.3,15.3c0,8.5,6.9,15.3,15.3,15.3l0,0c8.5,0,15.3-6.9,15.3-15.3
                C396.6,247.5,389.8,240.7,381.3,240.7z"/>
            <path d="M365.3,308.7H146.7c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.3,12.3,12.3h218.6c6.8,0,12.3-5.5,12.3-12.3S372.1,308.7,365.3,308.7
                z"/>
        </g>
    </svg>;
};

export default KeyboardIcon;
