import { Modals } from './entities';
import { RootState } from '../index';

// использовать вот так getModal('none' - 'none' это тип модалки)(state)
export const getModal = <Type extends Modals['type'], Modal extends Extract<Modals, { type: Type }>>(
    type?: Type
) => (state: RootState): Modal => {
        return state.modal as Modal;
    }
;
