import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import styles from './Select.module.scss';


export type SelectItemType<T> = {
    name: string;
    value: T;
};

export interface ToolbarSelectPropsInterface<T> {
    onChange: (value: T) => void;
    value?: T;
    options: SelectItemType<T>[];
}

export function Select<P>(props: ToolbarSelectPropsInterface<P>) {
    const { options, onChange, value } = props;

    const [opened, setOpened] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);

    const handleSelectClick = () => {
        setOpened((prevState) => !prevState);
    };

    const handleOptionClick = (selectedOption: SelectItemType<P>) => {
        setOpened(false);
        onChange(selectedOption.value);
    };

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (selectRef && !selectRef.current?.contains(event.target)) {
                setOpened(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const selectedOption = options.find((option) => {
        return value !== undefined ? option.value === value : true;
    });

    return (
        <div className={styles.wrap} ref={selectRef}>
            <div
                className={clsx(styles.selected, opened && styles.selected_opened)}
                onClick={handleSelectClick}
            >
                <div
                    className={styles.text}
                >
                    {selectedOption?.name}
                </div>
                <div className={clsx(styles.icon, opened && styles.icon_opened)}></div>
            </div>
            <div className={clsx(styles.options, opened && styles.options_opened)}>
                {options.map((option) => (
                    <div
                        className={clsx({
                            [styles.option]: true,
                            [styles.dropdown_selected]:
                                selectedOption?.value === option.value,
                        })}
                        key={String(option.value)}
                        onClick={() => handleOptionClick(option)}
                    >
                        {option.name}
                    </div>
                ))}
            </div>
        </div>
    );
};
