import { LangType } from 'entities';
import { RootState } from 'store';
import { MessagesType } from 'services/api/translates/entities';

export const getLang =
    (state: RootState): LangType =>
        state.translates.lang;

export const getTranslates =
    (state: RootState): MessagesType =>
        state.translates.messages;
